import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'; 
import { hotDrinks, coldDrinks, cookies } from './MenuItems';
import './App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { SpinningCircles } from 'react-loading-icons'


const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const MenuPage = lazy(() => import('./pages/MenuPage/MenuPage'));

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="app-container" data-aos="fade-zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="2000">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Header />
        <div className="content">
          <Suspense fallback={
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <SpinningCircles/>Loading...</div>}
            >
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/hot-drinks' element={<MenuPage name={"משקה חם"} menuItems={hotDrinks}/>} />
              <Route path='/cold-drinks' element={<MenuPage name={"משקה קר"} menuItems={coldDrinks} />} />
              <Route path='/cookies' element={<MenuPage name={"קוקיז"} menuItems={cookies} />} />
              {/* <Route path='/coffee-beans' element={<MenuPage name={"פולי קפה"} menuItems={hotDrinks}/>} />  */}
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;